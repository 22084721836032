import bigcommerceSparkDefaults from '../bigcommerce-spark/config.js';

const wheelsFields = bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

const fieldsForRelated = ['category', ...wheelsFields, ...tiresFields];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

export default {
  ...bigcommerceSparkDefaults,
  includes: ['bigcommerce-spark'],
  Widgets: [
    ...bigcommerceSparkDefaults.Widgets.filter(
      (w) => !['Garage', 'HomeVehicleWidget_custom'].includes(w.name),
    ),
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        lastChildOf: '#menu .navPages',
        class: 'cm_vehicle-widget__header-container',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      isAlwaysColumnLayout: true,
    },
    {
      name: 'RelatedItems',
      location: {
        replace: '#product-related',
        class: 'toggle productRelated',
      },
      template: 'product/relatedItems',
      count: 4,
      getSelection,
    },
  ],
};
