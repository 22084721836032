//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-900:_4000,_7816,_7240,_2388,_6852,_1112,_5608,_312;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-900')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-900', "_4000,_7816,_7240,_2388,_6852,_1112,_5608,_312");
        }
      }catch (ex) {
        console.error(ex);
      }